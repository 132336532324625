<template>
    <div>
        <div class="title">{{$t('titleI18n.BankCreat')}}</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item :label="$t('formTitleI18n.BankCountryCode')" prop="areaCode">
                <el-select v-model="ruleForm.areaCode" placeholder="">
                    <template v-if="language === 'zh-cn'">
                        <el-option v-for="item in optionsAreaCode" :key="item.code" :label="item.cnName" :value="item.code"></el-option>
                    </template>
                    <template v-else>
                        <el-option v-for="item in optionsAreaCode" :key="item.code" :label="item.enName" :value="item.code"></el-option>
                    </template>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('formTitleI18n.BankNameCN')" prop="cnName">
                <el-input v-model="ruleForm.cnName"></el-input>
            </el-form-item>
            <el-form-item :label="$t('formTitleI18n.BankNameEN')" prop="enName">
                <el-input v-model="ruleForm.enName"></el-input>
            </el-form-item>
            <el-form-item :label="$t('formTitleI18n.SwiftCode')" prop="swiftCode">
                <el-input v-model="ruleForm.swiftCode"></el-input>
            </el-form-item>
            <el-form-item :label="$t('formTitleI18n.BankLogo')" prop="icon">
                <el-upload
                    class="upload-demo"
                    action="/api/v1/upload/img"
                    accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :on-success="onSuccess"
                    :headers="{ token: this.$store.getters['auth/token'] }"
                    :limit="1"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                >
                    <el-button size="small" class="btn-black">{{$t('fileUpLoad')}}</el-button>
                    <span class="form-placeholder">{{ $t('formPlaceHolder.PhBankIcon') }}</span>
                </el-upload>
            </el-form-item>
            <el-form-item>
                <el-button @click="toBack">{{ $t("btnI18n.Cancel") }}</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">{{ $t("btnI18n.Save") }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "bank-info-ad",
    data() {
        return {
            fileList: [],
            optionsAreaCode: [],
            ruleForm: {
                areaCode: "",
                cnName: '',
                enName: '',
                swiftCode: '',
                icon: "",
            },
            rules: {
                areaCode: [
                    { required: true, message: this.$t("validateFormTips.requireCRCode"), trigger: 'blur' },
                    { required: true, message: this.$t("validateFormTips.requireCRCode"), trigger: 'change' },
                ],
                cnName: [
                    { required: true, message: this.$t("validateFormTips.requireBankName"), trigger: 'blur' },
                ],
                enName: [
                    { required: true, message: this.$t("validateFormTips.requireBankName"), trigger: 'blur' },
                ],
                swiftCode: [
                    { required: true, message: this.$t("validateFormTips.requireSwiftCode"), trigger: 'blur' },
                ],
                icon: [
                    { required: true, message: this.$t("validateFormTips.requireBankLogo"), trigger: 'blur' },
                ],
            }
        };
    },
    mounted() {
        this.initData();
        this.resetForm("ruleForm");
    },
    methods: {
        initData() {
            if (this.$route.query.id) {
                this.getBankInfo();
            } else {
                this.getAreaList();
            }
        },
        getAreaList() {
            let _this = this;
            let params = { page: 1, pageSize: 99999, enable: "1" };
            this.$axios.get('/manage-bank/area-list', { params: params }).then((res) => {
                _this.optionsAreaCode = res.data.rows || [];
                if (!_this.ruleForm.areaCode.toString()) {
                    _this.ruleForm.areaCode = res.data.rows[0].code;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getBankInfo() {
            const params = {
                id: this.$route.query.id,
            };
            let _this = this;
            this.$axios.get("/manage-bank/detail", { params: params }).then(res => {
                if (!res.code) {
                    _this.ruleForm = res.data;
                    _this.fileToList();
                    _this.getAreaList();
                }
            });
        },
        fileToList() {
            this.fileList = [];
            if (this.ruleForm.icon.includes("[")) {
                JSON.parse(this.ruleForm.icon).map((item) => {
                    this.fileList.push({
                        name: item.split("/")[item.split("/").length - 1],
                        url: item,
                    });
                });
            } else {
                this.fileList.push({
                    name: this.ruleForm.icon.split("/")[this.ruleForm.icon.split("/").length - 1],
                    url: this.ruleForm.icon,
                });
            }
        },
        handleRemove(file, fileList) {
            let dataArr = [];
            fileList.map((file) => {
                if (file.response) {
                    dataArr.push(file.response.data);
                }
            });
            // this.ruleForm.icon = JSON.stringify(dataArr);
            this.ruleForm.icon = dataArr.join("");
        },
        handleExceed(files, fileList) {
            this.$message({
                message: this.$t('companyCreate.limitedSelected5'),
                type: 'warning'
            });
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`${this.$t('sureRemove')} ${file.name}？`);
        },
        onSuccess(response, file, fileList) {
            if (response.data) {
                let dataArr = [];
                fileList.map((file) => {
                    if (file.response) {
                        dataArr.push(file.response.data);
                    }
                });
                // this.ruleForm.icon = JSON.stringify(dataArr);
                this.ruleForm.icon = dataArr.join("");
            }
        },
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    delete _this.ruleForm.createdAt;
                    delete _this.ruleForm.deletedAt;
                    delete _this.ruleForm.updatedAt;
                    delete _this.ruleForm.enable;
                    _this.ruleForm.areaCode = _this.ruleForm.areaCode.toString();
                    _this.$axios.post("/manage-bank/create", _this.ruleForm).then(res => {
                        if (!res.code) {
                            _this.toBack();
                        } else {
                            _this.$message.error(res.message);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        toBack() {
            this.resetForm("ruleForm");
            this.$router.push({path: "/bank-info"});
        },
    },
    computed: {
        language() {
            return this.$store.getters['auth/language'];
        }
    },
    watch: {
        language() {
            console.log(this.language);
        },
    },
};
</script>

<style scoped>

</style>
